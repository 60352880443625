export default () => {

    $('.l-utils__menu:not(.js-nav-done)').each(function() {
        var wrapper = $(this);
        var links = wrapper.find('.js-nav-link');
        var subs = wrapper.find('.js-sub-nav');

        // link click
        links.off('click').on('click', function() {
            var link = $(this);
            var sub = link.next('.js-sub-nav:first');
            if (!sub.length) return;

            if (link.is('.hover')) { // open
                link.removeClass('hover');
                sub.hide();
            }
            else { // closed
                links.removeClass('hover');
                subs.hide();
                link.addClass('hover');
                sub.show();
            }

            return false;
        });

        // anywhere click
        window.site.body.on('click', function(e) {
            if ($(e.target).closest('.l-utils__menu').length === 0) {
                links.removeClass('hover');
                subs.hide();
            }
        });

        // done
        wrapper.addClass('js-nav-done');
    });

}
